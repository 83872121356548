import {useState, useEffect } from "react";

import StrengthSet from "./StrengthSet";

const StrengthWorkoutSets = ({workout_id}) => {
    const [sets, setSets] = useState();

    useEffect(() => {
        fetch('https://bikesandbarbies.com/api/workout/' + workout_id + '/exercises', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(async response => {
            const json = await response.json();
            
            setSets(json.data);
        }).catch(error => console.log(error));
    }, [workout_id]);

    const renderSets = () => {
        const squatSets = (sets.squat?.sets || []).sort((a, b) => a.weight_lbs - b.weight_lbs);
        const benchSets = (sets['bench press']?.sets || []).sort((a, b) => a.weight_lbs - b.weight_lbs);
        const deadSets = (sets.deadlift?.sets || []).sort((a, b) => a.weight_lbs - b.weight_lbs);
        const pressSets = (sets.press?.sets || []).sort((a, b) => a.weight_lbs - b.weight_lbs);

        const rows = Math.max(
            squatSets.length, 
            benchSets.length,
            deadSets.length,
            pressSets.length)

        return (
            <div>
            <table>
                <thead>
                    <tr>
                        {squatSets.length ? <th className="squats" colSpan={5}>Squats</th> : null}
                        {benchSets.length ? <th className="bench" colSpan={5}>Bench</th> : null}
                        {deadSets.length ? <th className="deads" colSpan={5}>Deadlift</th> : null}
                        {pressSets.length ? <th className="press" colSpan={5}>Press</th> : null}
                    </tr>
                </thead>
                <tbody>
                    {Array(rows).fill().map((_, i) => 
                        <tr key={`row-${i}`}>
                            <StrengthSet sets={squatSets} index={i} />
                            <StrengthSet sets={benchSets} index={i} />
                            <StrengthSet sets={deadSets} index={i} />
                            <StrengthSet sets={pressSets} index={i} />
                        </tr>
                    )}
                </tbody>
            </table>
            </div>
        )
    };
    
    return (
        <div className="strength-workout-sets">
            {!sets ? 'Loading...' : null}
            {sets ? renderSets() : null}
        </div>
    );
};

export default StrengthWorkoutSets;