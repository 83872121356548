import easyRider from './bikes/easy';
import pop from './bikes/pop';
import monster from './bikes/monster';
import prick from './bikes/prick';
  
  const bikes = Object.freeze([
    pop,
    monster,
    prick,
    easyRider,
  ]);

  export default bikes;