const User = ({user}) => {
    if(!user) {
        return null;
    }

    const {first, last, email, birth, height, weight} = user;

    /*

        "id": 4230168,
    "first": "Mark",
    "last": "Henderson ",
    "email": "mark@artcog.com",
    "created_at": "2023-10-01T04:51:34.000Z",
    "updated_at": "2023-10-01T06:10:06.000Z",
    "birth": "1966-01-31",
    "gender": 0,
    "height": "1.778",
    "weight": "95.2545109815"
*/

    return <div className="user">
        <div>{first} {last}</div>
        <div>{email}</div>
        <div>{birth}</div>
        <div>{height}</div>
        <div>{weight}</div>
    </div>;
}

export default User;