import Grid from "./Grid";
import Thumbnail from "./Thumbnail";
import {useContent} from './contexts/content';

const Home = () => {
    const {categories} = useContent();

    return (
        <div>
            <Grid>
                {categories.map(category => <Thumbnail object={category} key={category.identifier} />)}
            </Grid>
        </div>
    );
};

export default Home;

