import {useEffect, useState} from "react";

import StrengthWorkoutSets from "./StrengthWorkoutSets";

const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
};

const StrengthWorkouts = () => {
    const [workouts, setWorkouts] = useState();
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        fetch('https://bikesandbarbies.com/api/workouts/1', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(async response => {
            const json = await response.json();
            
            setWorkouts(json.data);
        }).catch(error => console.log(error));
    }, []);

    const handleExpandWorkout = (workoutId) => {
        setExpanded(state => ({...state, [workoutId]: !state[workoutId]}));
    };

    return (
        <div className="strength-workouts">
            {!workouts ? 'Loading...' : null}
            {workouts ? (workouts || []).map(workout => {
                const {workout_id, user_id, date} = workout;
                const d1 = new Date(date); 
                const formattedDate = d1.toLocaleDateString('en-CA', dateOptions);               
                return (
                    <div className="strength-workouts-workout" key={workout_id} onClick={() => handleExpandWorkout(workout_id)}>
                        <div className="strength-workouts-date">{formattedDate}</div>
                        {expanded[workout_id] ? <StrengthWorkoutSets workout_id={workout_id} /> : null}
                   </div>
                )
            }) : null}   
        </div>
    )
};

export default StrengthWorkouts;