import { buttercupTags } from "./tags";

const packing = [
    {
        title: 'Packing Up',
        date: new Date('Sep 1, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing-painting.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1711.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1685.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1675.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1694.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1688.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1662.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing/IMG_1692.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
                text: "<p>Rocky's shoes.</p>",
            },
        ]
    }
];

export default packing;

