import {Link} from 'react-router-dom'

const Header = () => {
    return (
      <div className="header">
        <Link to="/"><div className="logo-blue" /></Link>
      </div>
    );
  }
  
export default Header;