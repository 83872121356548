const stories = [
    {
        title: 'The Rack',
        date: new Date('Jan 6, 2021'),
        content:[
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym16.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym10.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym01.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym02.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym04.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym05.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym06.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym07.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym08.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym09.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym11.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym12.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym13.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym14.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym15.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym17.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym18.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym19.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym20.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym21.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym22.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym23.jpg",
                tags: ['gym'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym24.jpg",
                tags: ['gym'],
            },
        ]
    },
  ]

  export default stories;