/* eslint-disable react/no-multi-comp */
import {
    createContext,
    useContext,
    useMemo,
  } from 'react';
import PropTypes from 'prop-types';

import defaultStories from '../content/default';
import gym from '../content/gym';
import movingIn from '../content/movingIn';
import monstercross from '../content/monstercross';
import grandCanyon from '../content/grandCanyon';
import birthday from '../content/birthday40';
import packing from '../content/packing';
import littleMountain from '../content/littleMountain';
import backyardOverhaul from '../content/backyard';
import toga from '../content/toga';
import paintingHouse from '../content/paintingHouse';
import fence from '../content/fences';
import meredithsBikeBuild from '../content/meredithsBikeBuild';
import bikes from '../content/bikes';
import paintings from '../content/paintings';
import categories from '../content/categories';
import abouts from '../content/abouts';

const stories = Object.freeze(
  {
    stories: defaultStories,
    gym,
    'moving-in': movingIn,
    monstercross,
    'grand-canyon': grandCanyon,
    birthday,
    packing,
    'little-mountain': littleMountain,
    'backyard-overhaul': backyardOverhaul,
    toga,
    'painting-house': paintingHouse,
    fence,
    'meredith-bike-build': meredithsBikeBuild,
  }
);

const getPath = art => art?.identifier ? `/${art.identifier}` : null;
  
export const ContentContext = createContext();

export const useContent = () => {
  const contentContext = useContext(ContentContext);

  if(contentContext === undefined) {
    throw new Error('useContent must be used within <ContentProvider />');
  }

  return contentContext;
};
  
const useContentInternal = () => {
  const getContent = identifier => [...paintings, ...bikes].find(c => c.identifier === identifier);
  const getStories = pathname => {
    return stories[pathname] || stories['stories'];
  }
  
  const value = useMemo(() => ({
    abouts,
    barbies: [...paintings],
    bikes,
    categories,
    paintings,
    stories,
    getStories,
    getContent,
    getPath
  }),[]);

  return value;
};
  
export const ContentProvider = ({children}, context) => {
  const value = useContentInternal(context);

  return (
    <ContentContext.Provider value={value}>
      {children}
    </ContentContext.Provider>
  );
};

ContentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const withContent = Component => {
  return props => (
    <ContentContext.Consumer>
      {contentContext => (
        <Component {...props} contentContext={{...contentContext}} />
      )}
    </ContentContext.Consumer>
  );
};
  
  