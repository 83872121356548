import {buttercupTags, littleMountainTags} from './tags';

const littleMountain = [
    {
        title: 'Little Mountain',
        date: new Date('Oct 21, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/lil-mountain2.jpg",
                tags: [...littleMountainTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/lil-mountain.jpg",
                tags: [...buttercupTags, ...littleMountainTags],
            },
        ]
    }
];

export default littleMountain;