import {
    createContext,
    useContext,
    useMemo,
    useCallback,
    useReducer
  } from 'react';
import PropTypes from 'prop-types';

import {user as mockedUser, workout as mockWorkout, workouts as mockWorkouts, workoutSummary as mockWorkoutSummary} from '../content/wahoo';

export const WahooContext = createContext();

export const useWahoo = () => {
  const wahooContext = useContext(WahooContext);

  if(wahooContext === undefined) {
    throw new Error('useContent must be used within <WahooProvider />');
  }

  return wahooContext;
};

const mockIt = false;
const workoutsPerPage = 10;
  
const useWahooInternal = () => {
    const [state, dispatch] = useReducer((state, action) => {
        const {accessToken, refreshToken, createdAt, expiresIn, scope} = action;
        const expiresAt = expiresIn ? Date.now() + (expiresIn * 1000) : null;
        switch(action.type) {
            case 'SET_TOKENS':
                return {
                    ...state, 
                    accessToken, 
                    refreshToken,
                    createdAt,
                    expiresIn,
                    expiresAt,
                    scope
                };
            default:
                return state;
        }
    }, {
    });

    const {
        accessToken, 
        refreshToken,
        expiresAt
    } = state;

    const clearTokens = useCallback(() => {
        dispatch({
            type: 'SET_TOKENS', 
            accessToken: null,
            refreshToken: null, 
            createdAt: null, 
            expiresIn: null, 
            scope: null
        });

    }, [dispatch]);

    const getToken = useCallback(async () => {
        if(!accessToken || !refreshToken) {
            clearTokens();
            return Promise.reject('No tokens');
        }

        if(expiresAt > Date.now()) {
            return Promise.resolve(accessToken);
        }

        return new Promise((resolve, reject) => {
            const key =`${process.env.REACT_APP_WAHOO_API_KEY}`;
            const client = `${process.env.REACT_APP_WAHOO_CLIENT_ID}`;
            const url = `https://api.wahooligan.com/oauth/token?client_secret=${key}&client_id=${client}&grant_type=refresh_token&refresh_token=${refreshToken}`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then(async (res) => {
                const json = await res.json();
                if(json) {
                    const {
                        access_token: accessToken, 
                        refresh_token: refreshToken,
                        created_at: createdAt,
                        expires_in: expiresIn,
                        scope,
                    } = json;
                    dispatch({type: 'SET_TOKENS', accessToken, refreshToken, createdAt, expiresIn, scope});
                    return resolve(accessToken);
                };

                clearTokens();
                reject();
            }).catch((err) => {
                clearTokens();
                reject(err);
            });
        });
    }, [accessToken, expiresAt, refreshToken, clearTokens]);

    const getAllWorkouts = useCallback(async page => {
        if(mockIt) {
            return Promise.resolve(mockWorkouts);
        }

        return new Promise((resolve, reject) => {
            getToken().then((accessToken) => {
                fetch(`https://api.wahooligan.com/v1/workouts?page=${page}&per_page=${workoutsPerPage}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }).then(async (res) => {
                    const json = await res.json();
                    if(json) {
                        return resolve(json);
                    };
    
                    reject();
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }, [getToken]);

    const getWorkoutSummary = useCallback(async (id) => {
        if(mockIt) {
            return Promise.resolve(mockWorkoutSummary);
        }

       return new Promise((resolve, reject) => {
            getToken().then((accessToken) => {
                fetch(`https://api.wahooligan.com/v1/workouts/${id}/workout_summary`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }).then(async (res) => {
                    const json = await res.json();
                    if(json) {
                        return resolve(json);
                    };
    
                    reject();
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }, [getToken]);

    const getWorkout = useCallback(async (id) => {
        if(mockIt) {
            return Promise.resolve(mockWorkout);
        }

        return new Promise((resolve, reject) => {
            getToken().then((accessToken) => {
                fetch(`https://api.wahooligan.com/v1/workouts/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }).then(async (res) => {
                    const json = await res.json();
                    if(json) {
                        return resolve(json);
                    };
    
                    reject();
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }, [getToken]);

    const getUser = useCallback(async () => {
        if(mockIt) {
            return Promise.resolve(mockedUser);
        }

        return new Promise((resolve, reject) => {
            getToken().then((accessToken) => {
                fetch('https://api.wahooligan.com/v1/user', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }).then(async (res) => {
                    const json = await res.json();
                    if(json) {
                        return resolve(json);
                    };
    
                    reject();
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }, [getToken]);

    const getWorkoutSummaryData = workoutSummary => {
        const {
            calories_accum,
            ascent_accum,
            cadence_avg,
            distance_accum,
            duration_active_accum,
            duration_paused_accum,
            duration_total_accum,
            heart_rate_avg,
            power_avg,
        } = workoutSummary || {};
    
        const hours = Math.floor(duration_total_accum / 3600);
        const mins = Math.floor((duration_total_accum % 3600) / 60);
        const durationString = `${hours ? hours : ''}${hours ? 'h:' : ''}${mins}m`;
    
        return {
            caloriesAccum: calories_accum,
            ascentAccum: ascent_accum,
            cadenceAvg: cadence_avg,
            distanceAccum: distance_accum,
            durationActiveAccum: duration_active_accum,
            durationPausedAccum: duration_paused_accum,
            durationTotalAccum: duration_total_accum,
            heartRateAvg: heart_rate_avg,
            powerAvg: power_avg,
            durationHours: hours,
            durationMins: mins,
            durationString
        }
    }

    const connect = useCallback(async code => {
        if(mockIt) {
            dispatch({type: 'SET_TOKENS', accessToken: 'ABC', refreshToken: 'XYZ', createdAt: Date.now(), expiresIn: 720000, scope: ''});
            return Promise.resolve();
        }

        if(!code) {
            return Promise.reject('No code provided');
        }

        return new Promise((resolve, reject) => {
            const key =`${process.env.REACT_APP_WAHOO_API_KEY}`;
            const redirectUrl = `${process.env.REACT_APP_WAHOO_REDIRECT_URI}`;
            const client = `${process.env.REACT_APP_WAHOO_CLIENT_ID}`;
            const url = `https://api.wahooligan.com/oauth/token?client_secret=${key}&redirect_uri=${redirectUrl}&code=${code}&grant_type=authorization_code&client_id=${client}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(async (res) => {
                const json = await res.json();
                if(json) {
                    const {
                        access_token: accessToken, 
                        refresh_token: refreshToken,
                        created_at: createdAt,
                        expires_in: expiresIn,
                        scope,
                    } = json;
                    dispatch({type: 'SET_TOKENS', accessToken, refreshToken, createdAt, expiresIn, scope});
                    return resolve();
                };

                reject();
            }).catch((err) => {
                reject(err);
            });
        });
    }, []);
  const value = useMemo(() => {
    const connected = mockIt || (accessToken && refreshToken);
    
    return {
        connect,
        connected,
        accessToken,
        refreshToken,
        getUser: connected ? getUser : null,
        getAllWorkouts: connected ? getAllWorkouts : null,
        getWorkout: connected ? getWorkout : null,
        getWorkoutSummary: connected ? getWorkoutSummary : null,
        getWorkoutSummaryData,
        workoutsPerPage
      }
  },[
    connect, 
    accessToken, 
    refreshToken,
    getAllWorkouts,
    getWorkout,
    getWorkoutSummary,
    getUser
]);

  return value;
};
  
export const WahooProvider = ({children}, context) => {
  const value = useWahooInternal(context);

  return (
    <WahooContext.Provider value={value}>
      {children}
    </WahooContext.Provider>
  );
};

WahooProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const withContent = Component => {
  return props => (
    <WahooContext.Consumer>
      {wahooContext => (
        <Component {...props} wahooContext={{...wahooContext}} />
      )}
    </WahooContext.Consumer>
  );
};