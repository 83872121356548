import { backyardOverhaulTags } from './tags';

const backyardOverhaul = [
    {
        title: 'Backyard Overhaul',
        date: new Date('Apr 15, 2019'),
        content: [
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/backyard-overhaul.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2026.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2053.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2054.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2055.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2056.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2057.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2058.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2063.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2064.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2066.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2069.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2072.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2079.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2083.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2087.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2088.jpg",
                text: '<p>The day the buttercup cried.</p>',
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2089.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2090.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2093.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2094.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2095.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2097.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2099.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                video: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2110.mp4",
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo-video-still.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2111.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2113.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2115.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2116.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2117.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2121.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2122.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2123.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2125.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2130.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2134.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2135.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2136.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2137.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2138.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2139.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2142.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2143.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2144.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2145.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2149.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2150.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2151.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2152.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2155.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2157.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2160.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2161.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2165.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2166.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2167.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2173.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2174.jpg",
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2037.jpg",
                text: '<p>Rotting deck bonus points.</p>',
                tags: [...backyardOverhaulTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/byo2049.jpg",
                tags: [...backyardOverhaulTags],
            },
        ]
    }
];

export default backyardOverhaul;