

import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {useEffect, useRef} from 'react';

const NavItem = ({route, children, onMouseOver, onClick, className}) => {
    const ref = useRef();
    const {pathname} = useLocation();
    useEffect(() => {
        // if(!children) {
        //     return;
        // }

        function handleMouseOver() {
            // const content = document.getElementById('content');
            onMouseOver(route);
            // content.classList.add('blur');
        }
      
        // function handleMouseOut() {
        //     const content = document.getElementById('content');
        //     content.classList.remove('blur');
        // }

        const el = ref.current;
      
        el?.addEventListener('mouseover', handleMouseOver);
        // el?.addEventListener('mouseout', handleMouseOut);
    
        return () => {
            el?.removeEventListener('mouseover', handleMouseOver);
            // el?.removeEventListener('mouseout', handleMouseOut);
        };
      }, []);
        
    return (
        <div className={classNames('nav-item')} ref={ref} onClick={onClick}>
            <Link className={classNames(route, {selected: pathname === `/${route}`})} to={`${route}`}>{route}</Link>
            <div className="nav-sub-items">
                {children}
            </div>
        </div>
    );
}

export default NavItem;