import { useEffect, useState, useMemo } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useWahoo } from "./contexts/wahoo";

const WorkoutDetail = () => {
    const {id} = useParams();
    const wahoo = useWahoo();
    const {getWorkout, connected} = wahoo;
    const [workout, setWorkout] = useState();
    const [workoutSummary, setWorkoutSummary] = useState();

    useEffect(() => {
        const summary = workout?.workout_summary;

        setWorkoutSummary(summary);
    }, [workout]);

    useEffect(() => {
        const fetchWorkout = async () => {
            try {
                const workout = await getWorkout(id);
                setWorkout(workout);
            }
            catch(err) {
                console.log(err);
            }
        }

        getWorkout && fetchWorkout();
    }, [getWorkout, id]);  
    
    const {
        name, 
        minutes,
        starts
    } = workout || {};
    const {
        calories_accum,
        ascent_accum,
        cadence_avg,
        distance_accum,
        duration_active_accum,
        duration_paused_accum,
        duration_total_accum,
        heart_rate_avg,
        power_avg,
        // power_bike_np_last,
        // power_bike_tss_last,
        // speed_avg,
        // work_accum,
        // file,
        // files
    } = workoutSummary || {};

    const startAt = useMemo(() => {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
        const date = new Date(starts);
        return date.toLocaleDateString("en-CA", options);
    }, [starts]);


    if(!connected) {
        return <Navigate to='/wahoo' replace={true} />
    }

    if(!workoutSummary || !workout) {
        return null;
    }
    /*
        "id": 251444276,
    "starts": "2023-10-14T15:23:28.000Z",
    "minutes": 0,
    "name": "Cycling",
    "plan_id": null,
    "workout_token": "FITNESS 44FF:4",
    "workout_type_id": 0,
    "workout_summary": {
        "id": 228689280,
        "ascent_accum": null,
        "calories_accum": "279.0",
        "cadence_avg": "67.87",
        "distance_accum": "12022.83",
        "duration_active_accum": "2279.0",
        "duration_paused_accum": "0.0",
        "duration_total_accum": "2279.0",
        "heart_rate_avg": "130.0",
        "power_avg": "122.38",
        "power_bike_np_last": "0.0",
        "power_bike_tss_last": "0.0",
        "speed_avg": "5.27",
        "work_accum": "0.0",
        "file": {
            "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
        },
        "files": [
            {
                "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
            }
        ],
        "created_at": "2023-10-14T16:01:37.000Z",
        "updated_at": "2023-10-14T16:01:37.000Z"
    },
    "created_at": "2023-10-14T16:01:37.000Z",
    "updated_at": "2023-10-14T16:01:37.000Z"

    */


    return (
        <div className="workout-summary">
            <div className="name">Name: {name}</div>
            <div className="name">When: {startAt}</div>
            <div className="name">Minutes: {minutes}</div>
            <div className="name">Calories: {calories_accum}</div>
            <div className="name">Ascent: {ascent_accum}</div>
            <div className="name">Cadence Avg: {cadence_avg}</div>
            <div className="name">Distance: {distance_accum}</div>
            <div className="name">Duration Active: {duration_active_accum}</div>
            <div className="name">Duration Paused: {duration_paused_accum}</div>
            <div className="name">Duration Total: {duration_total_accum}</div>
            <div className="name">Hear Rate Avg: {heart_rate_avg}</div>
            <div className="name">Power Avg: {power_avg}</div>
        </div>
    )
};

export default WorkoutDetail;