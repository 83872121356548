import React, {useState} from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import {Link} from 'react-router-dom';

const Story = ({title, date, content = [], types, children, onLinkTo}) => {
        const [posterVisible, setPosterVisible] = useState(content.some(item => item.poster));
        const handlePosterClick = e => {
            e && e.preventDefault();

            setPosterVisible(false);
        }
        return (
            <div className="work">
                {(title || date) && (<div className="work-header">
                    <div className="work-header_text-container">
                        {title && (<h1>{title}</h1>)}
                        {date && (<h2>{date && date.toDateString()}</h2>)}
                    </div>
                </div>)}
                <div className="work-body">
                    {content.map(({image, text, video, poster, linkTo}, i) => {
                        const imageContainerClass = className('image-container', {link: linkTo})
                        return (
                            <div className="work-container" key={`key_${i}`}>
                                {image && (
                                    <div className={imageContainerClass}>
                                        <img src={image} alt="" />
                                        {linkTo && <Link to={linkTo} title="Show more related images" className="work-link"><i className="read-more">show more</i></Link>}
                                    </div>)}
                                {video && !posterVisible && (
                                    <video 
                                        controls 
                                        autoPlay
                                        poster={poster}>
                                            <source 
                                                src={video} 
                                                type="video/mp4"/>
                                    </video>)}
                                {posterVisible && poster && (<div className="poster-container"><img className="poster" onClick={handlePosterClick} src={poster} alt="" /></div>)}
                                {text && (<div className="work-text" dangerouslySetInnerHTML={{__html: text}} />)}
                                
                            </div>
                        )
                    })}
                </div>
            </div>
        )
}

Story.propTypes = {
    title: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.object,
    children: PropTypes.node,
    onLinkTo: PropTypes.func
}

Story.defaultProps = {
    title: '',
    types: [],
    date: null,
    children: null,
    onLinkTo() {}
}

export default Story;