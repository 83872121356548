import { meredithsBikeBuildTags } from './tags';

const meredithsBikeBuild = [
    {
        title: 'Test Ride',
        date: new Date('Feb 7, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bike/IMG_4005.jpg",
                tags: [...meredithsBikeBuildTags],
                text: '<p>First test ride complete with riding coat.</p>'
            },
        ]
    },
    {
        title: 'Meredithߴs Bike Build',
        date: new Date('Nov 25, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/with-wheels.jpg",
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/IMG_3829.jpg",
                text: '<p>Chainstay braze-on.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/IMG_3832.jpg",
                text: '<p>Chainstay braze-on.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/IMG_3833.jpg",
                text: '<p>Chainstay braze-on.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/IMG_3834.jpg",
                text: '<p>Chainstay braze-on.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/brazeon.jpg",
                text: '<p>Chainstay braze-on.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/cableguide.jpg",
                text: '<p>Brazed on cable guide.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/ream-seattube.jpg",
                text: '<p>Reaming the seat tube.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/seatstay.jpg",
                text: '<p>Seatstay.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/seatstays.jpg",
                text: '<p>Seatstays to seat post.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/dropout.jpg",
                text: '<p>Tacked dropout.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/face-chase.jpg",
                text: '<p>Face & chase.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bb_cs.jpg",
                text: '<p>Filed bottom bracket.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bb-brazed-outside.jpg",
                text: '<p>Brazed chainstays.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/chain-mitre.jpg",
                text: '<p>Attaching the chainstays.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/mitre.jpg",
                text: '<p>Hand-filed chainstay.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/head-tube.jpg",
                text: '<p>Headtube.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bb-filed.jpg",
                text: '<p>Bottom bracket.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bb-brazed.jpg",
                text: '<p>Brazing the bottom bracket.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bb-dt.jpg",
                text: '<p>Attaching the bottom bracket to the downtube.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bb-hole.jpg",
                text: '<p>Drilling the bottom bracket.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/tubes2.jpg",
                text: '<p>Bike tubes.</p>',
                tags: [...meredithsBikeBuildTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/tubes-edited.jpg",
                text: '<p>Raw tubes.</p>',
                tags: [...meredithsBikeBuildTags],
            },
        ]
    },
];

export default meredithsBikeBuild;