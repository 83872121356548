const monster = {
    identifier: 'monster',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/Hendo13.jpg',
    title: 'monster',
    photos: [
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/Hendo13.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00001.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00002.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00003.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00004.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00005.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00006.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00007.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00008.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00009.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00010.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00011.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00012.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00013.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00014.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00015.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00016.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00017.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00018.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00019.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00020.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00021.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00022.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00023.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00024.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00025.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00026.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00027.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00028.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00029.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00030.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00031.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00032.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00033.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00034.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00001.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00002.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00003.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00004.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00005.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00006.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00007.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00008.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00009.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00010.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00011.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00012.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00013.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00014.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00015.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00016.jpg',
      },

    ]
};

export default monster;