import {useEffect, useState} from 'react';
import {useWahoo} from './contexts/wahoo';
import { Navigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import WorkoutListItem from './WorkoutListItem';
import User from './User';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import {Line} from 'react-chartjs-2';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const getTitle = display => {
    switch(display) {
        case 'heart':
            return 'Heart Rate';
        case 'power':
            return 'Power';
        case 'distance':
            return 'Distance';
        case 'duration':
            return 'Duration';
        case 'calories':
            return 'Calories';
        default:
            return '';
    }
}

  const getBorderColor = display => {
    switch(display) {
        case 'heart':
            return 'red';
        case 'power':
            return 'green';
        case 'distance':
            return 'blue';
        case 'duration':
            return 'orange';
        case 'calories':
            return 'blue';
        default:
            return 'black';
    }
}

const getBackgroundColor = display => {
    switch(display) {
        case 'heart':
            return 'rgba(255, 0, 0, 0.5)';
        case 'power':
            return 'rgba(0, 255, 0, 0.5)';
        case 'distance':
            return 'rgba(0, 0, 255, 0.5)';
        case 'duration':
            return 'rgba(255, 165, 0, 0.5)';
        case 'calories':
            return 'rgba(0, 0, 255, 0.5)';
        default:
            return 'rgba(0, 0, 0, 0.5)';
    }
}

const getValue = (workout, display, wahoo) => {
    const {workout_summary} = workout || {};
    const {getWorkoutSummaryData} = wahoo || {};
    const {heartRateAvg, powerAvg, distanceAccum, caloriesAccum, durationHours, durationMins, durationString} = getWorkoutSummaryData(workout_summary);
    switch(display) {
        case 'heart':
            return heartRateAvg ?? 0;
        case 'power':
            return powerAvg ?? 0;
        case 'distance':
            return distanceAccum ?? 0;
        case 'duration':
            return (durationHours >= 0 && durationMins >= 0) ? (durationHours * 60 + durationMins) : 0;
        case 'calories':
            return caloriesAccum ?? 0;
        default:
            return 0;
    }
}
  
const Workouts = () => {
    const wahoo = useWahoo();
    const {connected, getAllWorkouts, getUser, workoutsPerPage} = wahoo;
    const [workouts, setWorkouts] = useState([]);
    const [workoutsReversed, setWorkoutsReversed] = useState([]);
    const [user, setUser] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [display, setDisplay] = useState('all');
    const [totalWorkouts, setTotalWorkouts] = useState(0);
    const [lineData, setLineData] = useState({});
    const [maxY, setMaxY] = useState(0);
    const isHeart = display === 'heart';

    const options={
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 200
            }
        }
    }

    useEffect(() => {
        const labels = [];
        const datasets = [
            {
                id: 1,
                label: getTitle(display),
                data: [],
                borderColor: getBorderColor(display),
                backgroundColor: getBackgroundColor(display),
            },
        ];

        const dateOptions = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          };

        let max = 0;

        for(let i = 0; i < workouts.length; i++) {
            const {starts} = workouts[i] || {};
            const date = new Date(starts);
            labels.push([date.toLocaleDateString("en-US", dateOptions)]);
            const value = getValue(workouts[i], display, wahoo)
            setMaxY(value > max ? value : max);
            datasets[0].data.push(value);
        };

        setLineData({labels, datasets});
    }, [workouts, display, isHeart, wahoo]);

    useEffect(() => {
        const fetchAllWorkouts = async () => {
            try {
                const result = await getAllWorkouts(currentPage);
                const {
                    workouts,
                    total,
                    // page,
                    per_page,
                    // order,
                    // sort,
                } = result || {};
                setWorkouts([...workouts].reverse());
                setWorkoutsReversed(workouts);
                setTotalWorkouts(total);
                setPageCount(Math.ceil(total / per_page));
            }
            catch(err) {
                console.log(err);
            }
        }

        getAllWorkouts && fetchAllWorkouts();
    }, [getAllWorkouts, currentPage]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await getUser();
                setUser(user);
            }
            catch(err) {
                console.log(err);
            }
        }

        getUser && fetchUser();
    }, [getUser]);

    if(!connected) {
        return <Navigate to='/wahoo' replace={true} />
    }

    const handlePageClick = ({selected}) => {
        setCurrentPage(selected + 1);
    }

    return (
        <div className='workouts'>
            <header>
                <h1>Wahoo Workouts!</h1>
                <div className="header-select">
                    <select value={display} onChange={e => setDisplay(e?.target?.value || 'all')}>
                        <option value="all">Table View</option>
                        <option value="calories">Calories</option>
                        <option value="distance">Distance</option>
                        <option value="duration">Duration</option>
                        <option value="heart">Heart Rate</option>
                        <option value="power">Power</option>
                    </select>
                </div>
            </header>
            <div className="workouts-content">
                {display === 'all' ? <div className='workout-list'>
                    {workoutsReversed ? <table>
                    <thead>
                        <tr>
                            <th>📅</th>
                            <th>Name</th>
                            <th>❤️</th>
                            <th>🔋</th>
                            <th>🍔</th>
                            <th>📏</th>
                            <th>⌛️</th>
                            <th>✅</th>
                        </tr>
                    </thead>
                    <tbody>{workoutsReversed.map(workout => {
                        const {      
                            id,
                        } = workout;

                        return (
                            <WorkoutListItem key={id} workout={workout} />
                        )
                    })}</tbody></table> : null}
                </div>: null}
                {display !== 'all' ? <div className="workout-graph"> <Line 
                    options={{...options,
                        scales: {
                            y: maxY,
                            x: options.scales.x
                        }}}
                    datasetIdKey="id"
                    data={lineData} /></div> : null}
                <User user={user} />
            </div>
            {pageCount > 1 ? <div className='paginate-container'>
                <ReactPaginate 
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    subContainerClassName={'pages pagination'}
                    activeClassName={'paginator_active'} 
                    renderOnZeroPageCount={null}
                />
            </div> : null}
        </div>
    )
        };

export default Workouts;