import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {useState, useEffect} from 'react';

import {useContent} from './contexts/content';
import NavItem from './NavItem';

const Navigation = () => {
    const [currentRoute, setCurrentRoute] = useState();
    const bikesExpanded = currentRoute === 'bikes';
    const barbiesExpanded = currentRoute === 'barbies';
    const expanded = bikesExpanded || barbiesExpanded;
    const {bikes, barbies, getPath} = useContent();
    const navigate = useNavigate();

    useEffect(() => {
        if(currentRoute && expanded) {
            const content = document.getElementById('content');
            content.classList.add('blur');
        }
        else {
            const content = document.getElementById('content');
            content.classList.remove('blur');
        }
    }, [currentRoute, expanded]);

    const handleMouseOver = route => {
        setCurrentRoute(route);
    }

    const handleMouseOut = () => {
        setCurrentRoute();
    }

    const handleRouteSelected = () => {
        setCurrentRoute();
    }

    const handleSubItemSelected = object => {
        const path = getPath(object);
        navigate(path)
        setCurrentRoute();
    }

    return (
        <div className={classNames("nav-bar", {expanded})} onMouseLeave={handleMouseOut}>
            <NavItem route="bikes" onMouseOver={handleMouseOver} onClick={handleRouteSelected}/>
            <NavItem className="barbie" route="barbies" onMouseOver={handleMouseOver} onClick={handleRouteSelected}/>
            <NavItem route="about" onMouseOver={handleMouseOver} onClick={handleRouteSelected}/>
            <div className={classNames('nav-sub-items', {visible: ['bikes', 'barbies'].includes(currentRoute) })}>
                {bikes.map(bike =>  <div key={bike.identifier} onClick={() => handleSubItemSelected(bike)} className={classNames('nav-sub-item', {visible: bikesExpanded})}>{bike.title}</div>)}
                {barbies.map(barbie => <div key={barbie.identifier} onClick={() => handleSubItemSelected(barbie)} className={classNames('nav-sub-item', {visible: barbiesExpanded})}>{barbie.title}</div>)}
            </div>
        </div>
    );
}

export default Navigation;