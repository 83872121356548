import { housePaintingTags } from './tags';

const paintingHouse = [
    {
        title: 'Painting the House',
        date: new Date('Aug 25, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/house-post-paint.jpeg",
                tags: [...housePaintingTags],
                text: "<p>Painted house.</p>"
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/the-porch.jpeg",
                tags: [...housePaintingTags],
                text: "<p>The porch.</p>"
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3348.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3400.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/house-pre-paint.jpg",
                tags: [...housePaintingTags],
                text: "<p>Unpainted house.</p>"
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3295.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3393.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3388.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3358.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3353.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3347.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3355.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3357.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3354.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3301.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3334.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3336.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3346.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3306.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3290.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3284.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3326.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3304.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3335.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3303.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3332.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3324.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3325.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3331.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3329.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3233.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3308.jpg",
                tags: [...housePaintingTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/IMG_3227.jpg",
                tags: [...housePaintingTags],
                text: "<p>Stripped paint.</p>"
            },
            {
                video: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/renos/painting.mp4",
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/renos/painting.jpg",
                tags: [...housePaintingTags],
            },
        ]
    }
];

export default paintingHouse;