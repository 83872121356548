import { fenceTags, gardenFenceTags } from './tags';

const fenceWorks = [
    {
        title: 'Perimeter Fence',
        date: new Date('Oct 8, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/backyard.jpg",
                tags: [...fenceTags],
                text: "<p>Setting the posts for the first 90 of 700 feet.</p>",
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/fence.jpg",
                tags: [...fenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/fence2.jpg",
                tags: [...fenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/concrete.jpg",
                tags: [...fenceTags, 'concrete'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/concrete2.jpg",
                tags: [...fenceTags, 'concrete'],
                text: "<p>Portland Cement... your hair never looked so good.</p>",
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/fences/IMG_4911.jpg",
                tags: [...fenceTags, 'concrete'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/fences/IMG_3499.jpg",
                tags: [...fenceTags, 'concrete'],
                text: "<p>The Dingo.</p>",
            },
        ]
    },
    {
        title: 'Garden Fence',
        date: new Date('May 11, 2019'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2184.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2185.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2188.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2190.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2196.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2207.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2246.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2247.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2287.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2191.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2192.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2193.jpg",
                tags: [...gardenFenceTags],
            },
            {
                video: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2197.mp4",
                poster: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2197-still.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2200.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2201.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2202.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2203.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2205.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2217.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2220.jpg",
                tags: [...gardenFenceTags],
            },
            // {
            //     image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2224.jpg",
            //     tags: [...gardenFenceTags],
            // },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2237.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2238.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2244.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2245.jpg",
                tags: [...gardenFenceTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/deer-fence2262.jpg",
                tags: [...gardenFenceTags],
            },
        ]
    },
];

export default fenceWorks;