const getPopBikePhotos = () => {
    const images = [];
  
    for (let i = 1; i <= 227; i++) {
        const src = `https://s3-us-west-2.amazonaws.com/bikes-and-barbies/butterbike/pop-bike_${i}.jpg`;
  
        images.push({src})
    }
  
    return images;
  }
  
const pop = {
    identifier: 'pop',
    title: 'Pop Bike',
    titleShort: 'Pop',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/butterbike/pop-bike_227.jpg',
    thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/butterbike/pop-bike_227.jpg',
    blurbs: [
        <p key={1}>Influenced by Pop Art & Pink.</p>,
    ],
    photos: [
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/butterbike/pop-bike_227.jpg',
      },
      ...getPopBikePhotos()
    ]
  };

export default pop;