
import Grid from './Grid';
import Thumbnail from './Thumbnail';
import {useContent} from "./contexts/content";

const Barbies = () => {
    const {barbies} = useContent();
    
    return (
        <div>
            <Grid>
                {barbies.map(barbie => <Thumbnail object={barbie} key={barbie.identifier} />)}
            </Grid>
        </div>
    );
};

export default Barbies;
