import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {useContent} from "./contexts/content";

const Thumbnail = ({object}) => {
    const {title, titleShort, image, thumbnail, thumbnailPosition, blurbs} = object || {};
    const navigate = useNavigate();
    const {getPath} = useContent();
    const link = getPath(object);

    const style = {
        backgroundImage: `url(${thumbnail || image})`,
        backgroundPosition: thumbnailPosition || 'center',
    }

    const handleLink = () => {
        navigate(link);
    }

    return (
        <div className={classNames("thumbnail-container", {link})} style={style} onClick={link ? handleLink : null}>
            <div className="content">
                <h3>{titleShort || title}</h3>
                {blurbs}
            </div>
        </div>
    );
};

Thumbnail.propTypes = {

};

Thumbnail.defaultProps = {

};

export default Thumbnail;