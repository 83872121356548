import PropTypes from 'prop-types';

const StrengthSet = ({sets, index}) => {
    if(!sets || !sets.length ) {
        return null;
    }
    if(!sets || !sets.length || index < 0 || index >= sets.length) {
        return <><td className="td-first"></td><td></td><td></td><td></td><td className="td-last"></td></>;
    }

    const {repetitions, weight_lbs: weight, count} = sets[index];
    return (
        <>
        <td className="td-first">{parseInt(weight)} lbs</td>
        <td>x</td>
        <td>{repetitions}</td>
        <td>x</td>
        <td className="td-last">{count} {count > 1 ? 'sets' : 'set'}</td>
        </>
    )
}

StrengthSet.propTypes = {
    sets: PropTypes.array,
    index: PropTypes.number,
};

StrengthSet.defaultProps = {
    sets: [],
    index: 0,
};

export default StrengthSet;