const prick = {
    identifier: 'prick',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick01.png',
    title: 'the prick',
    titleShort: 'prick',
    thumbnailPosition: 'top',
    blurbs: [
        <p key={1}>Inspired by the 2009 Astana bicycle ridden by Lance Armstrong.</p>
    ],
    photos: [
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick01.png',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00001.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00002.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00003.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00004.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00005.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00006.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00007.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00008.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00009.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00010.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00011.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00012.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00013.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00014.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00015.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00016.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00017.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00018.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00019.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00020.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00021.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00022.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00023.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00024.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00025.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00026.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00027.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00028.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00030.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00031.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00032.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00033.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00034.jpg',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick-top.png',
      },
       {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/needles.jpg',
      },
    ]
};

export default prick;
