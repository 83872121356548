import SpanningImage from "./SpanningImage";
import {useContent} from './contexts/content';

const About = () => {
    const {abouts} = useContent();

    return (
        <div className="abouts">
            <SpanningImage src="https://s3-us-west-2.amazonaws.com/bikes-and-barbies/code.jpg" />
            {abouts.map((about, i) => {
                const {paragraphs, image} = about || {};

                const aboutParagraphs = paragraphs.map((paragraph, i) => {
                    return (<p key={i} dangerouslySetInnerHTML={{__html: paragraph}} />);
                });

               return (
                <div key={i} className="about-section">
                    <div className="about-image">
                        <img src={image} alt="" />
                    </div> 
                    <div className="about-paragraphs">
                        {aboutParagraphs}
                    </div>
                </div>) ;
            })}
        </div>
    );
};

export default About;