import { buttercupTags } from './tags';

const birthday40 = [
    {
        title: 'Buttercup Birthday',
        date: new Date('March 20, 2017'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/forty00.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0040.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0017.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0038.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0023.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0020.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0030.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birthday40/IMG_0042.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40']
            },
        ]
    
    }
];

export default birthday40;