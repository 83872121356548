import {BrowserRouter as Router} from 'react-router-dom'

import {ContentProvider} from './contexts/content';
import {WahooProvider} from './contexts/wahoo';
import AppRoutes from './AppRoutes';

import Header from './Header';
import Navigation from "./Navigation";

import './application.scss';

function App() {
  return (
    <Router>
      <WahooProvider>
        <ContentProvider>
            <Header/>
            <Navigation />
            <AppRoutes />
        </ContentProvider>  
      </WahooProvider>
    </Router>
  );
}

export default App;
