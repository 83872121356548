export const dogTags = ['toga', 'stinker', 'dog', 'lil stinker'];
export const littleMountainTags = ['woods', 'little mountain'];
export const grandCanyonTags = ['road trip', 'grand canyon'];
export const meredithsBikeBuildTags = ['buttercup', 'meredith', 'bike', 'bicycle', 'art', 'framebuilding'];
export const fenceTags = ['home', 'backyard', 'fence', 'yard', 'renos'];
export const gardenFenceTags = [...fenceTags, 'garden', 'deer'];
export const housePaintingTags = ['home', 'painting', 'renos'];
export const buttercupTags = ['buttercup', 'meredith'];
export const monsterTags = ['bikes', 'bicycles', 'hendo', 'monstercross'];
export const backyardOverhaulTags = ['home', 'renos', 'backyard', 'cleanup', 'bellevue', '1155', 'jordan'];

