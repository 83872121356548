export const workouts = {
    "workouts": [
        {
            "id": 251444276,
            "starts": "2023-10-14T15:23:28.000Z",
            "minutes": 0,
            "name": "Cycling",
            "plan_id": null,
            "workout_token": "FITNESS 44FF:4",
            "workout_type_id": 0,
            "workout_summary": {
                "id": 228689280,
                "ascent_accum": null,
                "calories_accum": "279.0",
                "cadence_avg": "67.87",
                "distance_accum": "12022.83",
                "duration_active_accum": "2279.0",
                "duration_paused_accum": "0.0",
                "duration_total_accum": "2279.0",
                "heart_rate_avg": "130.0",
                "power_avg": "122.38",
                "power_bike_np_last": "0.0",
                "power_bike_tss_last": "0.0",
                "speed_avg": "5.27",
                "work_accum": "0.0",
                "file": {
                    "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
                },
                "files": [
                    {
                        "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
                    }
                ],
                "created_at": "2023-10-14T16:01:37.000Z",
                "updated_at": "2023-10-14T16:01:37.000Z"
            },
            "created_at": "2023-10-14T16:01:37.000Z",
            "updated_at": "2023-10-14T16:01:37.000Z"
        },
        {
            "id": 251023471,
            "starts": "2023-10-12T04:46:19.000Z",
            "minutes": 0,
            "name": "Cycling",
            "plan_id": null,
            "workout_token": "FITNESS 44FF:3",
            "workout_type_id": 0,
            "workout_summary": {
                "id": 228338843,
                "ascent_accum": null,
                "calories_accum": "90.0",
                "cadence_avg": "75.8",
                "distance_accum": "4376.46",
                "duration_active_accum": "668.0",
                "duration_paused_accum": "0.0",
                "duration_total_accum": "668.0",
                "heart_rate_avg": "113.0",
                "power_avg": "133.83",
                "power_bike_np_last": "0.0",
                "power_bike_tss_last": "0.0",
                "speed_avg": "6.54",
                "work_accum": "0.0",
                "file": {
                    "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/_1A1ON9Z21DPfXpKzHcKUg/2023-10-12-044619-FITNESS_44FF-3-0.fit"
                },
                "files": [
                    {
                        "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/_1A1ON9Z21DPfXpKzHcKUg/2023-10-12-044619-FITNESS_44FF-3-0.fit"
                    }
                ],
                "created_at": "2023-10-12T04:57:34.000Z",
                "updated_at": "2023-10-12T04:57:34.000Z"
            },
            "created_at": "2023-10-12T04:57:34.000Z",
            "updated_at": "2023-10-12T04:57:34.000Z"
        },
        {
            "id": 250156166,
            "starts": "2023-10-07T15:01:53.000Z",
            "minutes": 0,
            "name": "Cycling",
            "plan_id": null,
            "workout_token": "FITNESS 44FF:2",
            "workout_type_id": 0,
            "workout_summary": {
                "id": 227622897,
                "ascent_accum": null,
                "calories_accum": "140.0",
                "cadence_avg": "70.85",
                "distance_accum": "4600.71",
                "duration_active_accum": "905.0",
                "duration_paused_accum": "0.0",
                "duration_total_accum": "905.0",
                "heart_rate_avg": null,
                "power_avg": "154.2",
                "power_bike_np_last": "0.0",
                "power_bike_tss_last": "0.0",
                "speed_avg": "5.08",
                "work_accum": "0.0",
                "file": {
                    "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/Hoqic1HXPm5JFoPPXNrbxg/2023-10-07-150153-FITNESS_44FF-2-0.fit"
                },
                "files": [
                    {
                        "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/Hoqic1HXPm5JFoPPXNrbxg/2023-10-07-150153-FITNESS_44FF-2-0.fit"
                    }
                ],
                "created_at": "2023-10-07T15:17:05.000Z",
                "updated_at": "2023-10-07T15:17:05.000Z"
            },
            "created_at": "2023-10-07T15:17:05.000Z",
            "updated_at": "2023-10-07T15:17:05.000Z"
        },
        {
            "id": 250150175,
            "starts": "2023-10-01T06:03:26.000Z",
            "minutes": 0,
            "name": "Cycling",
            "plan_id": null,
            "workout_token": "FITNESS 44FF:1",
            "workout_type_id": 0,
            "workout_summary": {
                "id": 227617577,
                "ascent_accum": null,
                "calories_accum": null,
                "cadence_avg": null,
                "distance_accum": "0.0",
                "duration_active_accum": "0.0",
                "duration_paused_accum": "0.0",
                "duration_total_accum": "0.0",
                "heart_rate_avg": null,
                "power_avg": null,
                "power_bike_np_last": "0.0",
                "power_bike_tss_last": "0.0",
                "speed_avg": "0.0",
                "work_accum": "0.0",
                "file": {
                    "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/E-tOlUDsQD_BrWtQA0GeOQ/2023-10-01-060326-FITNESS_44FF-1-0.fit"
                },
                "files": [
                    {
                        "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/E-tOlUDsQD_BrWtQA0GeOQ/2023-10-01-060326-FITNESS_44FF-1-0.fit"
                    }
                ],
                "created_at": "2023-10-07T14:56:39.000Z",
                "updated_at": "2023-10-07T14:56:39.000Z"
            },
            "created_at": "2023-10-07T14:56:38.000Z",
            "updated_at": "2023-10-07T14:56:38.000Z"
        }
    ],
    "total": 4,
    "page": 2,
    "per_page": 2,
    "order": "descending",
    "sort": "starts"
}

export const workoutSummary = {
    "id": 228689280,
    "ascent_accum": null,
    "calories_accum": "279.0",
    "cadence_avg": "67.87",
    "distance_accum": "12022.83",
    "duration_active_accum": "2279.0",
    "duration_paused_accum": "0.0",
    "duration_total_accum": "2279.0",
    "heart_rate_avg": "130.0",
    "power_avg": "122.38",
    "power_bike_np_last": "0.0",
    "power_bike_tss_last": "0.0",
    "speed_avg": "5.27",
    "work_accum": "0.0",
    "file": {
        "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
    },
    "files": [
        {
            "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
        }
    ],
    "created_at": "2023-10-14T16:01:37.000Z",
    "updated_at": "2023-10-14T16:01:37.000Z"
}

export const workout = {
    "id": 251444276,
    "starts": "2023-10-14T15:23:28.000Z",
    "minutes": 0,
    "name": "Cycling",
    "plan_id": null,
    "workout_token": "FITNESS 44FF:4",
    "workout_type_id": 0,
    "workout_summary": {
        "id": 228689280,
        "ascent_accum": null,
        "calories_accum": "279.0",
        "cadence_avg": "67.87",
        "distance_accum": "12022.83",
        "duration_active_accum": "2279.0",
        "duration_paused_accum": "0.0",
        "duration_total_accum": "2279.0",
        "heart_rate_avg": "130.0",
        "power_avg": "122.38",
        "power_bike_np_last": "0.0",
        "power_bike_tss_last": "0.0",
        "speed_avg": "5.27",
        "work_accum": "0.0",
        "file": {
            "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
        },
        "files": [
            {
                "url": "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/workout_file/file/NgnbKteLUsuJ4S1epKfG6w/2023-10-14-152328-FITNESS_44FF-4-0.fit"
            }
        ],
        "created_at": "2023-10-14T16:01:37.000Z",
        "updated_at": "2023-10-14T16:01:37.000Z"
    },
    "created_at": "2023-10-14T16:01:37.000Z",
    "updated_at": "2023-10-14T16:01:37.000Z"
}

export const user = {
    "id": 4230168,
    "first": "Mark",
    "last": "Henderson ",
    "email": "mark@artcog.com",
    "created_at": "2023-10-01T04:51:34.000Z",
    "updated_at": "2023-10-01T06:10:06.000Z",
    "birth": "1966-01-31",
    "gender": 0,
    "height": "1.778",
    "weight": "95.2545109815"
}