import { grandCanyonTags, buttercupTags } from './tags';

const stories = [
    {
        title: 'Grand Canyon Road Trip',
        date: new Date('Jun 1, 2015'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1024.jpg",
                text: '<p><em>Jun 10, 2015</em>.</p>',
                tags: [...grandCanyonTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/m-m.jpg",
                text: '<p><em>Jun 10, 2015</em>.</p>',
                tags: [...grandCanyonTags, 'food'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/roadkill-cafe.jpg",
                text: '<p>Seligman - Roadkill Cafe - <em>Jun 8, 2015</em>.</p>',
                tags: [...grandCanyonTags, ...buttercupTags, 'food']
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/colonel-allensworth-state-park.jpg",
                text: '<p>Colonel Allensworth Historic State Park - <em>Jun 5, 2015</em>.</p>',
                tags: [...grandCanyonTags, ...buttercupTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0984.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0962.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1012.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0985.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0974.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1001.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1013.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1006.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1039.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1041.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0983.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1017.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1033.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0950.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0996.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1042.jpg",
                tags: [...grandCanyonTags]
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_0975.jpg",
                tags: [...grandCanyonTags]
            },
        ]
    },
];

export default stories;