import {useLocation, useNavigate} from 'react-router-dom'
import {useContent} from './contexts/content';

import Story from './Story';

const processedStories = (stories, types = [], tags = []) => stories.reduce((acc, work) => {
    let yearGroup;
    const {title, date, year, content = []} = work;
    if(date) {
        yearGroup = date.getFullYear().toString();
    }
    else if(year) {
        yearGroup = year;
    }
    const filteredContent = content.reduce((acc2, entry) => {
        const {tags: workTags = []} = entry;

        if(yearGroup) {
            workTags.push(yearGroup);
        }
        if(title) {
            workTags.push(title.toLowerCase());
        }
        
        const tagMatch = !tags || !tags.length || tags.some(tag => workTags.includes(tag));
        if(!tagMatch) {
            return acc2;
        }
        if(!types || !types.length || Object.keys(entry).some(key => types.includes(key))) {
            acc2.push({...entry});
            return acc2;
        }

        return acc2;
    }, []);

    if(!filteredContent.length || !yearGroup) {
        return acc;
    }

    acc.push({...work, yearGroup, content: filteredContent});
    return acc;
}, []);

const Stories = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {getStories} = useContent();
    const {pathname, search} = location;

    const queryParams = new URLSearchParams(search);
    const tags = queryParams.getAll('tag');
    const types = queryParams.getAll('type');

    const handleOnLinkTo = linkTo => {
        navigate(linkTo);
    }

    const getContentWithTags = (tags, types) => {
        const yearGroups = {};
        const stories = getStories((pathname || '').replace('/', ''));
        processedStories(stories, types, tags).forEach(work => {
            const {yearGroup} = work;

            if(yearGroup) {
                const workArray = yearGroups[yearGroup] || [];
                workArray.push(work);
                yearGroups[yearGroup] = workArray;
            }
        })
        
        return Object.keys(yearGroups).sort((y1, y2) => y2.localeCompare(y1)).reduce((acc, yearKey) => {
            const workArray = yearGroups[yearKey] || [];
            
            if(workArray.length) {
                acc.push(<div key={yearKey} className="work-in-progress_header"><h1>{yearKey}</h1></div>)
                acc.push(...workArray.map(work => {
                    const {title, date, content, linkTo} = work;
                    return (
                        <Story 
                            title={title}
                            key={title} 
                            date={date}
                            linkTo={linkTo}
                            onLinkTo={handleOnLinkTo}
                            content={content}>
                        </Story>
                    );
                }))
            }

            return acc;
        }, []);
    }

    const content = getContentWithTags(tags, types);

    return (
        <div className="work-in-progress">
            {content}
        </div>
    );
}

export default Stories;

