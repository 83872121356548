const easy = {
    identifier: 'easy',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er10.jpeg',
    title: 'easy rider',
    titleShort: 'easy',
    blurbs: [
        <p key={1}>Based on the movie, the motorcycle, and Peter Fonda's shirt.</p>
    ],
    photos: [
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/easy-flower.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/peter.jpg',
        note: 'The shirt.'
      },
      {
        src: 'https://bikes-and-barbies.s3-us-west-2.amazonaws.com/chopper.jpg',
        note: 'The motorcycle.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er00.jpg',
        note: 'The bike.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still05.jpg',
        note: 'Frame ready for priming.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still.jpg',
        note: 'Attaching the top tube braze-ons.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still02.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still03.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still04.jpg',
        note: 'Braze-ons.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-primed.jpg',
        note: 'Frame primed and now ready for some color.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-yellow-seat-tube.jpg',
        note: 'Starting with the seat tube, paint it yellow to match the shirt base color.'
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-masking.jpg',
        note: 'Here I apply the mask for the pink paint on the seat tube.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-paint-pink.jpg',
      note: 'Color mixed in airbursh cup.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-pink.jpg',
      note: 'A pink flower emerging.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-red-mask.jpg',
      note: 'Next up the mask is applied for the red paint.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-mask2.jpg',
      note: 'Mask detail for red paint.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-red-pink.jpg',
      note: 'Some red flower details appear after applying the paint through the mask.',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-detail.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-tube-color-full.jpg',
      note: 'The final version of the seat tube with all flower colors applied.',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-tube-color-full2.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-tube-color3.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-flag.jpg',
        note: 'Next I work on the Stars and Stripes on the top and head tubes.',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-flag2.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-flag-blue.jpg',
      note: 'I lay a base color of blue before painting the stars.',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-star-mask.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-painting-flag.jpg',
      },
      {
       src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er10.jpeg',
      note: 'The completed flag.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-bike-masked.jpg',
      note: 'Next up I mask the frame in order to lay down the chrome and black paints.',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-chrome.jpg',
      note: 'Some chrome detail.',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-chrome2.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-easy-mask.jpg',
      note: 'And now the bike is masked to paint the black seat- and chainstays.',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-mask-abstract.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-easy-painted.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-mask-rings.jpg',
      },
      {
        src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-oh.jpg',
      },
      {
      src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-final-left.jpg',
      note: 'The finished frame.',
      },
      {
       src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er14.jpeg',
      },
      {
     src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/helmet.jpg',
      note: 'And a helmet side project.',
      },
]
}

export default easy;