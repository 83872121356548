import {useEffect} from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {useWahoo} from './contexts/wahoo';

const Wahoo = () => {
    const wahoo = useWahoo();
    const {connect, connected} = wahoo;
    const location = useLocation();
    const {search} = location;
    const params = new URLSearchParams(search);
    const code = params.get('code');

    useEffect(() => {
        if(!code || connected) {
            return;
        }

        const wahooConnect = async () => {
            try {
                await connect(code);
            }
            catch(err) {
                console.log(err);
            }
        }

        wahooConnect();
    }, [connect, code, connected]);

    if(connected) {
        return <Navigate to='/wahoo/workouts' replace={true} />
    }

    return (
        <div className='wahoo'>
            <h1>Wahoo!</h1>
            <a href={`https://api.wahooligan.com/oauth/authorize?client_id=${process.env.REACT_APP_WAHOO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_WAHOO_REDIRECT_URI}&scope=user_write+email+workouts_read+workouts_write+power_zones_read+power_zones_write+offline_data+user_read&response_type=code`}>Login</a>
        </div>
    );
}

export default Wahoo;