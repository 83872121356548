import {buttercupTags} from "./tags";

const stories = [
    {
        title: 'Moving In',
        date: new Date('Oct 1, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-day.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-day2.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1742.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1757.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1758.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1760.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1763.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1764.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1767.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1776.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1799.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1727.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1734.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1740.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1747.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1779.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1785.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1802.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1804.jpg",
                tags: [...buttercupTags, 'moving'],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-in_1805.jpg",
                tags: [...buttercupTags, 'moving'],
            },
        ]
    },
];

export default stories;