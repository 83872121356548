import { monsterTags } from './tags';

const stories = [
    {
        title: 'Monstercross',
        date: new Date('Mar 7, 2015'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00004.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00001.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00002.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00003.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00005.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00006.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00007.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00008.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00009.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00010.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00011.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00012.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00013.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00014.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00015.jpg",
                tags: [...monsterTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00016.jpg",
                tags: [...monsterTags],
            },
        ]
    },
];

export default stories;