import Grid from './Grid';
import Thumbnail from './Thumbnail';
import {useContent} from "./contexts/content";

const Bikes = () => {
    const {bikes} = useContent();

    return (
        <div>
            <Grid>
                {bikes.map(bike => <Thumbnail object={bike} key={bike.identifier} />)}
            </Grid>
        </div>
    );
};

export default Bikes;
