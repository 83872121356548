const categories = Object.freeze([
    {
      identifier: 'bikes',
      title: 'bikes',
      thumbnailPosition: 'top',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00008.jpg'
    },
    {
      identifier: 'barbies',
      title: 'barbies',
      thumbnailPosition: 'top',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/barbie-caution-invert.png'
    },
  ]);

  export default categories;