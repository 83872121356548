const abouts = Object.freeze([
    {
      title: 'me',
      titleOnRight: true,
      position: 'top',
      //image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/coke-subject.png",
      // image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bikes/the-kiss.jpg",
      //image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/chuck-atlas.png",
      image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/chuck-atlas.png",
      //image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/baby.jpg",
      masked: false,
      paragraphs: [
        "I studied Painting &amp; <a href='https://www.moma.org/collection/works/61239'>Printmaking</a> at <a href='https://www.ecuad.ca'>Emily Carr University of Art &amp; Design, </a> Computer Science at <a href='https://www.sfu.ca'>Simon Fraser University, </a>and Metalworking &amp; Carpentry at <a href='https://www.bcit.ca'>BCIT. </a>I learned how to build bicycles under the skilled eye of Paul Brodie, founder of <a href='https://www.brodiebicycles.com'>Brodie Bicycles. </a>",
        'I live with my muse, <a href="https://flamingoroom.ca">Meredith Nicole</a>, and our dogs, <a href="https://bikesandbarbies.com/toga">Toga</a> and <a href="https://bikesandbarbies.com/dj">DJ</a>, in beautiful <a href="https://www.google.com/maps/place/Errington,+BC/@49.279746,-124.4162263,12z/data=!3m1!4b1!4m5!3m4!1s0x54888e2c49f61541:0x9e3a1488f0b26c2e!8m2!3d49.2894701!4d-124.3591725">Errington, </a> near Parksville B.C., while working on our ultimate art project and drinking the occassional Jagged Face... and all of the below...'
      ]
   },
   {
    title: 'bikes',
    titleOnRight: true,
    position: 'top',
    image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bike-frontal.png",
    backgroundImage: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo.jpg",
    paragraphs: [
      "Bikes may very well be my ultimate art pursuit. They offer nothing but positive... even in the rain. They hammer you into shape. They move you. They keep you young. You can ride the high road or get down in the dirt. When you combine all that goodness with my interests in art, metalworking, painting, and graphic design, we get excited. I can go deeper. Now, I braze, weld, and paint them. Slice, dice, and chop them.",
      "The possibilities are endless."
    ],
    quotes: [
      {
          quote: "Every time I see an adult on a bicycle, I no longer despair for the future of the human race.",
          author: "H.G. Wells"
      }
    ]
  },
  {
    title: 'barbies',
    titleOnRight: true,
    position: 'top',
    // image: "https://bikes-and-barbies.s3.us-west-2.amazonaws.com/bike/cleaver-silhouette-black.png",
    //image: "https://bikes-and-barbies.s3.us-west-2.amazonaws.com/barb-selected.png",
    image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/coke-subject.png",
    //image: "https://bikes-and-barbies.s3.us-west-2.amazonaws.com/bike/barbie-caution.png",
    backgroundImage: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/barbie-caution-invert.png",
    paragraphs: [
      "The Barbies are everywhere. I first painted one in Ken & Barbie. Barbies is all that is not bike. They represent my paintings and all that is whimsical in my art. If it's not a bike, it's a barbie. Although sometimes the lines are blurred."
  ]
  },
  {
    title: 'art',
    titleOnRight: true,
    position: 'center',
    image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/venus.jpg",
    //image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/kiss-cropped.jpg",
    //image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/slump-square.png",
    backgroundImage: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/polar-bear.jpg",
    paragraphs: [
      "Art, in all her wisdom, suggested that there is more than one answer to any given question... don't be too rigid in your thinking.",
      "And Art knows best. And I ignored her at the most inopportune times... but not anymore."
    ],
    quotes: [
      {
          quote: "Be open to other solutions",
          author: "she said"
      },
      {
          quote: "You're not going to make any money doing that",
          author: "he said"
      }
    ],
  },
  // {
  //   title: 'code',
  //   titleOnRight: true,
  //   position: 'center',
  //   backgroundImage: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/code.jpg',
  //   backgroundColor: '#1d1d1d',
  //   color: '#aaa',
  //   paragraphs: [
  //     '<span className="ide blue-dark">I\'ve written software <span class="ide green">professionally</span> for <span class="ide pink">decades.</span> </span><span class="ide green">I currently write web and mobile apps for </span><a href="https://klue.com">Klue. </a> <span class="ide pink">I worked at </span><a href="https://www.phaseone.com">PHASEONE</a> <span class="ide blue-light">implementing parts of their Professional RAW image editing software, </span><a href="https://www.captureone.com/en/products/pro">Capture One</a>.'
  //   ],
    // content: [
    //     {
    //         icons: [
    //             klue,
    //             slp,
    //             phase,
    //             react,
    //             apple,
    //             andy, 
    //             icon00,
    //             icon8800,
    //             icon01,
    //             icon02,
    //             icon03,
    //             icon04,
    //             icon8801,
    //             icon05,
    //             icon06,
    //             icon07,
    //             icon8802,
    //             icon08,
    //             icon09,
    //             icon10,
    //             icon11,
    //             icon12,
    //             icon8803
    //         ]
    //     }
  //},
  ]);

  export default abouts;