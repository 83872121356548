import React from 'react';
import {useLocation} from 'react-router-dom';
import {useContent} from './contexts/content';

const Bike = () => {
    const location = useLocation();
    const {pathname = ''} = location;
    const identifier = pathname.replace('/', '');
    const {getContent} = useContent();

    if(!identifier) {
        return null;
    }

    const {title, titleShort, image, thumbnail, blurbs, attributes, photos} = getContent(identifier);

    return (
        <div className="bike">

            <div className="photos">
                {photos.map(({src, note}, i) => {
                    return (<div key={i}>
                        <img src={src} alt="" />
                        {note && <div className="note"><p>{note}</p></div>}
                    </div>)
                })}
                {/* <h1>{title}</h1>
                {attributes && attributes.length && (
                    <ul>
                        {attributes.map((a, i) => (<li key={`li_${i}`}>{a}</li>))}
                    </ul>
                )} */}
                {/* {(description || []).map((d, i) => (<p key={`p_${i}`}>{d}</p>))} */}
            </div>
            {/* <img src={image} alt="" /> */}
        </div>
    );
}

export default Bike;