import {
    grandCanyonTags,
    monsterTags,
    buttercupTags,
    littleMountainTags,
    dogTags,
    housePaintingTags,
    backyardOverhaulTags,
    fenceTags,
    meredithsBikeBuildTags 
} from './tags';

const stories = [
    {
        title: 'The Rack',
        date: new Date('Jan 6, 2021'),
        content:[
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/home-gym16.jpg",
                tags: ['gym'],
                text: '<p>The start of the home-gym build.</p>',
                linkTo: '/gym',
            },
        ]
    },
    {
        title: 'The Woods',
        date: new Date('Oct 31, 2020'),
        content:[
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/woods.jpg",
                tags: [...littleMountainTags, ...dogTags],
                text: '<p>The woods across the street.</p>'
            },
        ]
    },
    {
        title: 'Meredithߴs Bike Build',
        date: new Date('Nov 25, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/with-wheels.jpg",
                tags: [...meredithsBikeBuildTags],
                linkTo: '/meredith-bike-build',
            },
        ]
    },
    {
        title: 'Fences',
        date: new Date('Oct 8, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/backyard.jpg",
                tags: [...fenceTags],
                text: "<p>Setting the posts for the first 90 of 700 feet.</p>",
                linkTo: '/fence',
            },
        ]
    },
    {
        title: 'Painting the House',
        date: new Date('Aug 25, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/house-post-paint.jpeg",
                tags: [...housePaintingTags],
                text: "<p>Painted house.</p>",
                linkTo: '/painting-house'
            },
        ]
    },
    {
        title: 'Sold', 
        date: new Date('May 24, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/mc.jpg",
                tags: ['motorcycle'],
            },
        ]
    },
    {
        title: 'The Dog',
        date: new Date('Mar 21, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga.jpg",
                tags: [...dogTags],
                linkTo: '/toga'
            }
        ]
    },
    {
        title: 'Frame Jig',
        date: new Date('Feb 23, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/jig-stand.jpg",
                tags: ['jig', 'framebuilding', 'bike', 'bicycle'],
            },
        ]
    },
    {
        title: 'Beware of Dog',
        date: new Date('Jan 5, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/beware.jpg",
                tags: [...dogTags, 'painting', 'art'],
            },
        ]
    },
    {
        title: 'Backyard Overhaul',
        date: new Date('Apr 15, 2019'),
        content: [
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/backyard-overhaul.jpg",
                tags: [...backyardOverhaulTags],
                linkTo: '/backyard-overhaul'
            }
        ]
    },
    {
        title: 'Rathtrevor Beach',
        date: new Date('Mar 29, 2019'),
        content: [
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/rathtrevor.jpeg",
                tags: ['parksville', 'ocean', 'beach', 'rathtrevor'],
            }
        ]
    },
    {
        title: 'Flamingo Room Setup',
        date: new Date('Nov 11, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/flamingo-setup.jpg",
                tags: ['flamingo room', 'flamingo', ...buttercupTags],
            },
        ]
    },
    {
        title: 'Little Mountain',
        date: new Date('Oct 21, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/lil-mountain2.jpg",
                tags: [...littleMountainTags],
                linkTo: '/little-mountain'
            },
        ]
    },
    {
        title: 'Apple Tree Shaker',
        date: new Date('Oct 18, 2018'),
        content: [
            {
                video: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/apple-shaker.mp4",
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/apple-shaker.jpg",
                tags: [...buttercupTags, 'backyard', 'apples'],
            }
        ]
    }, 
    {
        title: 'Moving In',
        date: new Date('Oct 1, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/moving-day.jpg",
                tags: [...buttercupTags, 'moving'],
                linkTo: '/moving-in'
            },
        ]
    },
    {
        title: 'Packing Up',
        date: new Date('Sep 1, 2018'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/packing-painting.jpg",
                tags: [...buttercupTags, 'moving', 'arc', 'the arc'],
                linkTo: '/packing'
            },
        ]
    },
    {
        title: 'Iron Workers Bridge Trail',
        date: new Date('Feb 2, 2017'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/iron-workers-trail.jpg",
                tags: ['the arc']
            },
        ]
    },
    {
        title: 'Buttercup Birthday',
        date: new Date('March 20, 2017'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/forty00.jpg",
                tags: ['the arc', ...buttercupTags, 'birthday', '40'],
                linkTo: '/birthday'
            },
        ]
    },
    {
        title: 'Salt Spring Island',
        // tags: ['buttercup', 'meredith', 'ocean'],
        date: new Date('May 2, 2017'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/salt-spring-ruckle-provincial-park.jpg",
                text: '<p>Ruckle Provincial Park.</p>',
                tags: [...buttercupTags, 'ocean', 'salt spring island'],
            },
        ]
    },
    {
        title: 'Numbskull',
        // tags: ['buttercup', 'meredith', 'ocean'],
        date: new Date('Oct 28, 2016'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/numbskull.jpg",
                tags: ['the arc', 'beer'],
            },
        ]
    },
    {
        title: 'Fifty',
        // tags: ['buttercup', 'meredith', 'ocean'],
        date: new Date('Jan 30, 2016'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/fifty-spread.jpg",
                text: '<p>The night before.</p>',
                tags: ['the arc'],
            },
        ]
    },
    {
        title: 'Grand Canyon Road Trip',
        date: new Date('Jun 1, 2015'),
        // tags: ['road trip', 'grand canyon'],
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/grand-canyon/IMG_1024.jpg",
                text: '<p><em>Jun 10, 2015</em>.</p>',
                tags: [...grandCanyonTags],
                linkTo: '/grand-canyon',
            },
        ]
    },
    {
        title: 'Monstercross',
        date: new Date('Mar 7, 2015'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00004.jpg",
                tags: [...monsterTags],
                linkTo: '/monstercross',
            },
        ]
    },
];

export default stories;