import { dogTags, littleMountainTags } from './tags';

const toga = [
    {
        title: 'Wide-eyed Toga, Looking Back, & Airborne',
        date: new Date('Mar 30, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/wide-eyed-toga.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/airborne-2021-03.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/looking-back-toga.jpg",
                tags: [...dogTags],
            }
        ]
    },
    {
        title: 'Toga in a toga',
        date: new Date('Mar 27, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-in-toga.jpg",
                tags: [...dogTags],
          }
        ]
    },
    {
        title: 'Trail Dog',
        date: new Date('Feb 25, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/trail-dog0.jpg",
                tags: [...dogTags],
                text: "<p>Preparing to eat my front tire.</p>"
          }
        ]
    },
    {
        title: 'Frisbee Tug-o-War',
        date: new Date('Feb 23, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/frisbee02.jpg",
                tags: [...dogTags],
            }
        ]
    },
    {
        title: 'Another Snow Day',
        date: new Date('Feb 13, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-snow01.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-snow02.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-snow03.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-snow04.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'First Snow',
        date: new Date('Feb 10, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_4006.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Toga',
        date: new Date('Jan 24, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_3971.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_3972.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_3973.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Toga\'s Spot',
        date: new Date('Nov 18, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_3732.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Little Burt Reynolds',
        date: new Date('Oct 12, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/little-burt-reynolds.jpeg",
                tags: [...dogTags, 'burt', 'reynolds', 'burt reynolds'],
            },
        ]
    },
{
        title: 'New Trail',
        date: new Date('Jan 23, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/new-trail0.jpg",
                tags: [...littleMountainTags, ...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/new-trail2.jpg",
                tags: [...littleMountainTags, ...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga-car.jpg",
                tags: [...littleMountainTags, ...dogTags],
            },
        ]
    },
    {
        title: 'The Log',
        date: new Date('Jan 9, 2021'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-log.png",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Little Mountain Summer',
        date: new Date('Jun 7, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/happy-dog.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/sprint.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/stinker-tongue.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00000.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00004.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-photos.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00002.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00001.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00003.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00010.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00007.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00009.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00011.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00005.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00008.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00012.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00015.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00014.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00016.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00017.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00013.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00019.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00021.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00018.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00023.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00022.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00024.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00020.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00026.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00030.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00032.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00031.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00025.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/tongue.jpg",
                tags: [...dogTags],
            },
        ]
    },    
    {
        title: 'Airborne',
        date: new Date('May 30, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga00006.jpg",
                tags: [...littleMountainTags, ...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga00033.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/stinker-jump0.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/stinker-jump1.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Howl',
        date: new Date('May 2, 2020'),
        content: [
            {
                video: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/howl-may2-2020.mp4",
                tags: [...dogTags],
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/howl-still.jpg"
            }
        ]
    },
    {
        title: 'Stoned',
        date: new Date('Apr 29, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/stoner.jpg",
                tags: [...dogTags],
                text: "<p>Taking our wobbly dog to the vet.</p>"
            },
        ]   
    },
    {
        title: 'The Fly',
        date: new Date('Apr 15, 2020'),
        content: [
            {
                video: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/toga/the-fly.mov",
                tags: [...dogTags],
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/toga/the-fly-still.jpg"
            }
        ]
    },
    {
        title: 'Dapper Dog',
        date: new Date('Mar 21, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Neutered', 
        date: new Date('Mar 6, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/neutered.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Little Mountain Winter',
        date: new Date('Feb 20, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/jump.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/happy.jpg",
                tags: [...dogTags],
            }
        ]
    },
    {
        title: 'Tired Toga',
        date: new Date('Feb 7, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_2627.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_2619.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/sleepy.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Fangs',
        date: new Date('Jan 7, 2020'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/teeth2.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-blur01.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-blur.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-blur03.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-blur00.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/toga-blur02.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Frisbee',
        date: new Date('Dec 2, 2019'),
        content: [
            {
                video: "https://bikes-and-barbies-share.s3-us-west-2.amazonaws.com/frisbee01.mp4",
                tags: [...dogTags, 'front yard'],
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/frisbee.jpg"
            }
        ]
    },
    {
        title: 'Surfer Dude',
        date: new Date('Oct 12, 2019'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/surfer-dude.jpg",
                tags: [...dogTags],
                text: "<p>Columbia Beach.</p>"
            },
        ]   
    },
    {
        title: 'Tail Wagon',
        date: new Date('Oct 12, 2019'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/tail-wagon0.jpg",
                tags: [...dogTags],
            },
        ]   
    },
    {
        title: 'Puppy School',
        date: new Date('Sept 7, 2019'),
        content: [
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/spin.gif",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_3576.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_2582.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'The Wobbler',
        date: new Date('Jul 23, 2019'),
        content: [
            {
                video: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/toga-wobbler-jul-23-2019.mp4",
                tags: [...dogTags, 'wobbler'],
                poster: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/toga-wobbler-still.jpg"
            }
        ]
    },
    {
        title: 'New Home',
        date: new Date('Jul 4, 2019'),
        content: [
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2308.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2338.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2319.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2344.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2335.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2353.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2340.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2315.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/toga-elephant.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2346.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2332.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/new-home/IMG_2324.jpg",
                tags: [...dogTags],
            },
        ]
    },
    {
        title: 'Meeting Toga',
        date: new Date('Jun 7, 2019'),
        content: [
            {
                image: "https://bikes-and-barbies.s3-us-west-2.amazonaws.com/meeting-toga.jpg",
                tags: [...dogTags],
            },
            {
                image: "https://s3-us-west-2.amazonaws.com/bikes-and-barbies/toga/IMG_2269.jpg",
                tags: [...dogTags],
            },
        ]
    },
];

export default toga;