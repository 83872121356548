import classNames from "classnames";

const SpanningImage = ({src, alt = '', className, style}) => {
    return (
        <div className={classNames("full-image-container", className)} style={style}>
            <img src={src} alt={alt} />
        </div>
    );
}

export default SpanningImage;